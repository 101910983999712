import React, { useEffect, useState, useCallback } from 'react'
import classNames from 'classnames'
import useEmblaCarousel from 'embla-carousel-react'
import { motion } from 'framer-motion'
import { ArrowIcon } from '@/components/ArrowIcon'
import { Img } from '@/components/Img'
import { variants, bgVariations } from './Lightbox.motion'
import * as styles from './Lightbox.module.scss'

const Lightbox = ({ items = [], startIndex = 0, isOpen, onClose }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const totalItems = items?.length || 0

  /**
   * Embla Carousel
   */

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [])

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return
    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  /**
   * Respond to start index change
   */

  useEffect(() => {
    if (isOpen) {
      if (emblaApi) emblaApi.scrollTo(startIndex, true)
    }
  }, [isOpen, startIndex, emblaApi])

  /**
   * Keyboard controls
   */

  const handleKeyPress = useCallback(
    (event) => {
      switch (event.keyCode) {
        case 27: // ESC
          onClose()
          break
        case 37: // LEFT
          scrollPrev()
          break
        case 39: // RIGHT
          scrollNext()
          break
        default:
      }
    },
    [scrollPrev, scrollNext] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false)

    return () => {
      document.removeEventListener('keydown', handleKeyPress, false)
    }
  }, [handleKeyPress])

  return (
    <div className={styles.LightBox} data-is-open={isOpen}>
      <motion.div
        className={styles.bg}
        variants={bgVariations}
        initial="initial"
        animate={isOpen ? 'visible' : 'hidden'}
      />
      <div className={styles.close}>
        <motion.div
          className={styles.close__inner}
          variants={variants}
          animate={isOpen ? 'visible' : 'hidden'}
        >
          <button type="button" onClick={onClose} className={styles.close__btn}>
            Close
          </button>
        </motion.div>
      </div>
      <motion.div
        className={styles.container}
        variants={variants}
        animate={isOpen ? 'visible' : 'hidden'}
      >
        {totalItems > 1 && (
          <>
            <button className={styles.prev} onClick={scrollPrev}>
              <ArrowIcon
                className={styles.prev__button}
                size="14px"
                direction="left"
              >
                Prev
              </ArrowIcon>
            </button>
            <button className={styles.next} onClick={scrollNext}>
              <ArrowIcon
                className={styles.next__button}
                size="14px"
                direction="right"
              >
                Next
              </ArrowIcon>
            </button>
            <div className={styles.counter}>
              {selectedIndex + 1} of {totalItems}
            </div>
          </>
        )}
        <div className={styles.embla} ref={emblaRef}>
          <div className={styles.embla__container}>
            {items.map(({ image, caption, id }, index) => {
              return (
                <div
                  className={classNames(styles.embla__item, {
                    [styles.isActive]: index === selectedIndex,
                  })}
                  key={id}
                >
                  <div className={styles.item__container}>
                    <div
                      className={styles.item__inner}
                      style={{
                        maxWidth: `${image.width}px`,
                        maxHeight: `${image.height}px`,
                      }}
                    >
                      {image && (image.srcset || image.src) && (
                        <Img
                          {...image}
                          objectFit="contain"
                          fillContainer
                          placeholder={null}
                        />
                      )}
                    </div>
                    {caption && (
                      <div className={styles.item__credit}>
                        <div>Image: {caption}</div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export { Lightbox }
