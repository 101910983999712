export const duration = 0.5

export const variants = {
  initial: {
    y: '100%',
  },
  hidden: {
    y: '100%',
    transition: { duration, ease: 'circOut' },
  },
  visible: {
    y: '0%',
    transition: {
      delay: 0.1,
      duration,
      ease: 'circOut',
    },
  },
}

export const bgVariations = {
  hidden: {
    scaleY: 0,
    transition: {
      delay: 0.1,
      duration,
      ease: 'circOut',
    },
  },
  visible: {
    scaleY: 1,
    transition: { duration, ease: 'circOut' },
  },
}
