// https://khalilstemmler.com/articles/gatsby-related-posts-component/

import { sortBy } from './sortBy'
import { includes } from './includes'

export class GetSimilarPosts {
  // (1.) Create by passing in articles, currentUid
  constructor(articles, currentArticleUid) {
    // (2.) Don't include the current article in articles list
    this.articles = articles.filter(
      (aArticle) => aArticle.uid !== currentArticleUid
    )

    this.currentArticleUid = currentArticleUid
    // (3.) Set default values
    this.maxArticles = 3
    this.categories = []
    this.tags = []
  }

  // (4.) Builder pattern usage
  setMaxArticles(m) {
    this.maxArticles = m
    return this
  }

  setCategories(categoryArray) {
    this.categories = categoryArray || []
    return this
  }

  setTags(tagArray) {
    this.tags = tagArray || []
    return this
  }

  getArticles() {
    const { categories, tags, articles, maxArticles } = this
    // (5.) We use an Identity Map to keep track of score
    const identityMap = {}

    // if (!!categories === false || categories.length === 0) {
    // return []
    // }

    // if (!!category === false) {
    //  console.error(
    // /    'SimilarArticlesFactory: Category not provided, use setCategory().'
    //  )
    //  return []
    // }

    function getUid(article) {
      return article.uid
    }

    function addToMap(article) {
      const uid = getUid(article)

      if (!Object.prototype.hasOwnProperty.call(identityMap, uid)) {
        identityMap[uid] = article
        identityMap[uid].points = 0
        // identityMap[uid] = {
        // article,
        // points: 0,
        // }
      }
    }

    // (7.) For category matches, we add 2 points
    // function addCategoryPoints(article, category) {
    //  const categoryPoints = 2
    //  const uid = getUid(article)

    //  if (article.category === category) {
    //    identityMap[uid].points += categoryPoints
    //  }
    // }

    // (8.) For tags matches, we add 1 point
    function addCategoryPoints(article, theseCategories) {
      const categoryPoint = 1
      const uid = getUid(article)

      if (article.categories) {
        article.categories.forEach((category) => {
          if (includes(theseCategories, category)) {
            identityMap[uid].points += categoryPoint
          }
        })
      }
    }

    function addTagPoints(article, theseTags) {
      const tagPoint = 1
      const uid = getUid(article)

      if (article.tags) {
        article.tags.forEach((tag) => {
          if (includes(theseTags, tag)) {
            identityMap[uid].points += tagPoint
          }
        })
      }
    }

    function getIdentityMapAsArray() {
      return Object.keys(identityMap).map((uid) => identityMap[uid])
    }

    // (6.) Map over all articles, add to map and add points
    articles.forEach((article) => {
      addToMap(article)
      // addCategoryPoints(article, category)
      addCategoryPoints(article, categories)
      addTagPoints(article, tags)
    })

    // (9.) Convert the identity map to an array
    const arrayIdentityMap = getIdentityMapAsArray()

    // (10.) Use a lodash utility function to sort them
    // by points, from greatest to least
    const similarArticles = sortBy(arrayIdentityMap, 'points').reverse()

    // (11. Take the max number articles requested)
    return similarArticles.splice(0, maxArticles)
  }
}
