import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '@/components/RichText'
import { ProjectLightbox } from '@/components/ProjectLightbox'
import { BlockRenderer } from '@/components/BlockRenderer'
import { RelatedProjects } from '@/components/RelatedProjects'
import { Container } from '@/components/Container'
import { Details } from '@/components/Details'
import { Hero } from '@/blocks/Hero'
import * as styles from './Project.module.scss'

const Project = ({
  uid,
  image,
  imageAlign,
  imageMobile,
  title,
  clients,
  year,
  location,
  size,
  aboriginalCountry,
  team,
  partners,
  awards,
  text,
  categories,
  blocks,
  relatedProjects,
}) => {
  const detailsArray = [
    { title: 'Client', id: 'details-client', content: clients },
    { title: 'Year', id: 'details-year', content: year },
    { title: 'Location', id: 'details-location', content: location },
    {
      title: 'Aboriginal Country',
      id: 'details-aboriginal-country',
      content: aboriginalCountry,
    },
    { title: 'Size', id: 'details-size', content: size },
    { title: 'Team', id: 'details-team', content: team },
    { title: 'Collaborators', id: 'details-partners', content: partners },
    {
      title: 'Awards',
      id: 'details-awards',
      content: awards,
      layout: 'wide',
    },
  ]

  const detailsOutput = []
  detailsArray.forEach((detail) => {
    if (detail.content && detail.content.length !== 0) {
      detailsOutput.push(detail)
    }
  })

  return (
    <ProjectLightbox
      image={image}
      blocks={blocks}
      render={(p) => (
        <div>
          <Hero
            images={[
              {
                id: `project-hero-${uid}`,
                src: image.src,
                objectPosition: imageAlign && `center ${imageAlign}`,
              },
            ]}
            mobileImages={[
              {
                id: `project-hero-mobile-${uid}`,
                src: imageMobile.src,
                objectPosition: imageAlign && `center ${imageAlign}`,
              },
            ]}
          />
          <div className={styles.intro}>
            <Container>
              <div className={styles.intro__container}>
                <div className={styles.intro__primary}>
                  <h1 className={styles.heading}>{title}</h1>
                  <div className={styles.credits}>
                    {!!clients.length && (
                      <div className={styles.credits__row}>
                        <div className={styles.credits__label}>Client</div>
                        <div className={styles.credits__value}>
                          {clients.map((client) => {
                            return (
                              <div key={`introClients-${client.id}`}>
                                {client.link?.url ? (
                                  <a
                                    href={client.link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {client.title}
                                  </a>
                                ) : (
                                  client.title
                                )}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                    {year && (
                      <div className={styles.credits__row}>
                        <div className={styles.credits__label}>Year</div>
                        <div className={styles.credits__value}>{year}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.intro__secondary}>
                  {text && (
                    <div className={styles.intro__text}>
                      <RichText>{text}</RichText>
                    </div>
                  )}
                  {!!categories.length && (
                    <ul className={styles.categories}>
                      {categories.map((item) => {
                        return (
                          <li
                            key={`cat-${item.uid}`}
                            className={styles.categories__item}
                          >
                            <a
                              href={item.link}
                              className={styles.categories__item__link}
                            >
                              {item.title}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </Container>
          </div>
          <BlockRenderer blocks={p.blocks} />
          {!!detailsArray.length && <Details items={detailsOutput} />}
          <RelatedProjects
            title="Similar Projects"
            projects={relatedProjects}
          />
        </div>
      )}
    />
  )
}

Project.defaultProps = {
  image: {},
  imageAlign: 'default',
  imageMobile: {},
  clients: [],
  team: [],
  partners: [],
  awards: [],
  categories: [],
  blocks: [],
  relatedProjects: [],
}

Project.propTypes = {
  uid: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.node,
  image: PropTypes.object,
  imageAlign: PropTypes.string,
  imageMobile: PropTypes.object,
  clients: PropTypes.array,
  size: PropTypes.string,
  location: PropTypes.node,
  aboriginalCountry: PropTypes.node,
  team: PropTypes.array,
  partners: PropTypes.array,
  awards: PropTypes.array,
  year: PropTypes.string,
  categories: PropTypes.array,
  blocks: PropTypes.array,
  relatedProjects: PropTypes.array,
}

export default Project
