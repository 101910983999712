import React from 'react'
import PropTypes from 'prop-types'
import { ProjectThumb } from '@/components/ProjectThumb'
import { Container } from '@/components/Container'
import { Grid, GridRow, GridColumn } from '@/components/Grid'
import { Block } from '@/components/Block'
import * as styles from './RelatedProjects.module.scss'

const RelatedProjects = ({ title, projects, background }) => {
  return (
    <Block topPadding={false} bottomPadding={false}>
      <div className={styles.container} data-background={background}>
        <Container>
          <h3 className={styles.heading}>{title}</h3>
        </Container>
        {!!projects?.length && (
          <div>
            <Grid>
              <GridRow>
                {projects.map((project) => (
                  <GridColumn
                    className={styles.column}
                    key={project.route}
                    width="4"
                  >
                    <ProjectThumb
                      title={project.title}
                      url={project.route}
                      image={project.image}
                      grayscale={false}
                      background={background}
                    />
                  </GridColumn>
                ))}
              </GridRow>
            </Grid>
          </div>
        )}
      </div>
    </Block>
  )
}

RelatedProjects.defaultProps = {
  title: 'Related Projects',
  background: 'default',
}

RelatedProjects.propTypes = {
  title: PropTypes.string,
  projects: PropTypes.array,
  background: PropTypes.string,
}

export { RelatedProjects }
