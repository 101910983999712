import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GetSimilarPosts } from '@/utils/GetSimilarPosts'
import { useProjects } from '@/prismic/hooks/useProjects'
import { getRichText } from '@/prismic/helpers/getRichText'
import { usePreviewData } from '@/prismic/hooks/usePreviewData'
import { useSlices } from '@/prismic/hooks/useSlices'
import { linkResolver } from '@/prismic/helpers/linkResolver'
import { getImage } from '@/prismic/helpers/getImage'
import Project from '@/templates/Project'

const ProjectPrismic = ({ data }) => {
  const { prismicProject } = usePreviewData(data)
  const title = prismicProject.data?.title
  const yearStart = prismicProject.data?.year_start
  const yearEnd = prismicProject.data?.year_end

  let year
  if (yearStart && yearEnd) {
    year = `${yearStart}–${yearEnd}`
  } else if (yearStart) {
    year = `${yearStart}–present`
  }

  const categories = []
  if (!!prismicProject.data?.categories?.length) {
    prismicProject.data.categories.forEach((item) => {
      const slug = item.category.document?.uid // (previously item.category?.slug)
      const title = item.category.document?.data?.title

      if (slug) {
        categories.push({
          title,
          uid: slug,
          link: linkResolver({
            type: 'category',
            uid: slug,
          }),
        })
      }
    })
  }

  const tags = []
  if (!!prismicProject.data?.tags?.length) {
    prismicProject.data.tags.forEach((item) => {
      tags.push({ uid: item.tag.slug })
    })
  }

  const blocks = useSlices(prismicProject.data.body)

  const team = []
  if (prismicProject.data?.team) {
    prismicProject.data.team.forEach((item) => {
      if (item.profile?.uid !== null) {
        const hasPage = item.profile?.document?.data?.profile_page === 'True'
        const isCurrent = item.profile?.document?.data?.current_staff === 'True'
        const link =
          hasPage && isCurrent
            ? {
                url: linkResolver({
                  type: 'profile',
                  uid: item.profile.uid,
                }),
              }
            : null

        team.push({
          id: `team-${item.profile.uid}`,
          title: item.profile?.document?.data.name,
          link,
        })
      }
    })
  }

  const details = {
    partners: {
      src: prismicProject.data?.partners,
    },
    awards: {
      src: prismicProject.data?.awards,
    },
    clients: {
      src: prismicProject.data?.clients,
    },
  }

  Object.keys(details).forEach((key) => {
    details[key].output = []
    if (details[key].src) {
      details[key].src.forEach((item, i) => {
        if (item.title) {
          details[key].output.push({
            id: `${key}-${i + 1}`,
            ...item,
          })
        }
      })
    }
  })

  const allProjects = useProjects()
  const relatedProjects = new GetSimilarPosts(allProjects, prismicProject.uid)
    .setMaxArticles(3)
    .setCategories(categories.map((category) => category.uid))
    .setTags(tags.map((category) => category.uid))
    .getArticles()

  return (
    <Project
      uid={prismicProject.uid}
      image={getImage(prismicProject.data?.desktop_image)}
      imageMobile={getImage(prismicProject.data?.mobile_image)}
      imageAlign={
        prismicProject.data.image_align
          ? prismicProject.data.image_align.toLowerCase()
          : 'default'
      }
      title={title}
      clients={details.clients.output}
      year={year}
      size={prismicProject.data.size}
      location={getRichText(prismicProject.data?.location?.raw)}
      aboriginalCountry={getRichText(
        prismicProject.data?.aboriginal_country?.raw
      )}
      awards={details.awards.output}
      partners={details.partners.output}
      team={team}
      text={
        getRichText(prismicProject.data?.text?.raw) ||
        prismicProject.data?.short_summary
      }
      categories={categories}
      blocks={blocks}
      relatedProjects={relatedProjects}
    />
  )
}

ProjectPrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProjectPrismic

export const pageQuery = graphql`
  query ProjectByID($id: String!) {
    prismicProject(id: { eq: $id }) {
      uid
      type
      data {
        title
        text {
          raw
        }
        short_summary
        desktop_image: image {
          fixed(height: 2500, width: 2000) {
            ...GatsbyPrismicImageFixed_noBase64
          }
          dimensions {
            width
            height
          }
          alt
          mobile: fixed(height: 800, width: 800) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        mobile_image: image {
          fixed(height: 800, width: 800) {
            ...GatsbyPrismicImageFixed_noBase64
          }
          alt
        }
        image_align
        categories {
          category {
            slug
            document {
              ... on PrismicCategory {
                uid
                data {
                  title
                }
              }
            }
          }
        }
        tags {
          tag {
            slug
          }
        }
        year_end
        year_start
        location {
          raw
        }
        size
        aboriginal_country {
          raw
        }
        awards {
          title
          link {
            url
          }
        }
        partners {
          title
          link {
            url
          }
        }
        clients {
          title
          link {
            url
          }
        }
        team {
          profile {
            uid
            document {
              ... on PrismicProfile {
                id
                data {
                  name
                  profile_page
                  current_staff
                }
              }
            }
          }
        }
        body {
          ... on PrismicProjectBodyBigImage {
            id
            slice_type
            items {
              image {
                fluid(maxWidth: 1600) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              credit {
                raw
              }
            }
          }
          ... on PrismicProjectBodyBodyText {
            id
            slice_type
            primary {
              column01 {
                raw
              }
              column02 {
                raw
              }
              valign
            }
          }
          ... on PrismicProjectBodyMultiImageLayout {
            id
            slice_type
            primary {
              background
              align
            }
            items {
              image {
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              credit {
                raw
              }
              valign
            }
          }
        }
      }
    }
  }
`
