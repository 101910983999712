import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import { Img } from '@/components/Img'
import * as styles from './ProjectThumb.module.scss'

const ProjectThumb = ({ title, url, image, grayscale, background }) => (
  <div
    className={styles.ProjectThumb}
    data-grayscale={grayscale}
    data-background={background}
  >
    <div className={styles.image}>
      {(image?.srcset || image?.src) && (
        <span className={styles.image__inner}>
          <span className={styles.image__hover}>
            <span className={styles.image__filter}>
              <Link to={url}>
                <Img {...image} fillContainer />
              </Link>
            </span>
          </span>
        </span>
      )}
      <span className={styles.image__overlay} />
    </div>
    <div className={styles.text}>
      <Link to={url}>{title}</Link>
    </div>
  </div>
)

ProjectThumb.defaultProps = {
  grayscale: true,
  background: 'default',
}

ProjectThumb.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.object,
  grayscale: PropTypes.bool,
  background: PropTypes.string,
}

export { ProjectThumb }
