// extracted by mini-css-extract-plugin
export var LightBox = "Lightbox-module--LightBox--gFjna";
export var aniSpin = "Lightbox-module--ani-spin--Rr6VB";
export var bg = "Lightbox-module--bg--wYATw";
export var carousel = "Lightbox-module--carousel--b0uU3";
export var close = "Lightbox-module--close--leh-3";
export var close__btn = "Lightbox-module--close__btn--8Jufm";
export var close__inner = "Lightbox-module--close__inner--hj5sE";
export var container = "Lightbox-module--container--z6hNK";
export var counter = "Lightbox-module--counter--R1VIg";
export var embla = "Lightbox-module--embla--zLTNc";
export var embla__container = "Lightbox-module--embla__container--eveFr";
export var embla__item = "Lightbox-module--embla__item--WyAKU";
export var isActive = "Lightbox-module--isActive--D449G";
export var item__container = "Lightbox-module--item__container--5+4Y5";
export var item__credit = "Lightbox-module--item__credit--ti6lX";
export var item__inner = "Lightbox-module--item__inner--TUPgG";
export var next = "Lightbox-module--next--VJrIf";
export var next__button = "Lightbox-module--next__button--FWdet";
export var prev = "Lightbox-module--prev--Qwwg6";
export var prev__button = "Lightbox-module--prev__button--x+-WE";