// extracted by mini-css-extract-plugin
export var aniSpin = "Project-module--ani-spin--jk68J";
export var categories = "Project-module--categories--BXGul";
export var categories__item = "Project-module--categories__item--YR-1l";
export var categories__item__link = "Project-module--categories__item__link--iviu4";
export var credits = "Project-module--credits--97Xgo";
export var credits__label = "Project-module--credits__label--lUxue";
export var credits__row = "Project-module--credits__row--goYM0";
export var credits__value = "Project-module--credits__value--5skU1";
export var heading = "Project-module--heading--KJA-d";
export var intro = "Project-module--intro--91unO";
export var intro__container = "Project-module--intro__container--E8jYL";
export var intro__primary = "Project-module--intro__primary--UQRcF";
export var intro__secondary = "Project-module--intro__secondary--uURgX";
export var intro__text = "Project-module--intro__text--uSYBE";