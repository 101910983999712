/**
 * includes: includes: check if a value is in collection
 * @source https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
 * @param {Array} array
 */

const includes = array => {
  // Native (does not use same value zero)
  return array.indexOf(1) > -1
}

exports.includes = includes
