import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container } from '@/components/Container'
import { Link } from '@/components/Link'
import * as styles from './Details.module.scss'

const Details = ({ items }) => {
  return (
    <div className={styles.Details}>
      <div className={styles.container}>
        <Container>
          <div className={styles.row}>
            {items.map((item) => {
              return (
                <div
                  className={classNames(styles.item, {
                    [styles.wide]: item.layout === 'wide',
                  })}
                  key={item.id}
                >
                  <h6 className={styles.heading}>{item.title}</h6>
                  <div className={styles.content}>
                    {Array.isArray(item.content)
                      ? item.content.map((i) => {
                          return (
                            <p key={i.id} className={styles.content__item}>
                              <Link to={i.link?.url} target="_blank">
                                {i.title}
                              </Link>
                            </p>
                          )
                        })
                      : item.content}
                  </div>
                </div>
              )
            })}
          </div>
        </Container>
      </div>
    </div>
  )
}

Details.defaultProps = {
  items: [],
}

Details.propTypes = {
  items: PropTypes.array,
}

export { Details }
