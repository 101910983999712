import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Lightbox } from '@/components/Lightbox'

const ProjectLightbox = ({ image, blocks, render }) => {
  const [imageItems, setImageItems] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [startIndex, setStartIndex] = useState(0)

  useEffect(() => {
    const items = []

    const openLightbox = (withItem) => {
      setStartIndex(Math.max(items.indexOf(withItem), 0))
      setIsOpen(true)
    }

    const addItem = (img, caption, id) => {
      if (img && (img.srcset || img.src)) {
        const item = { image: img, caption, id }
        items.push(item)
        img.onClick = () => {
          openLightbox(item)
        }
        img.attributes = {
          ...img.attributes,
          'data-lightbox-enabled': true,
        }
      }
    }

    addItem(
      image,
      undefined, // no credit is currently provided for the hero image
      'project-lightbox-hero-image'
    )

    blocks.forEach((module) => {
      if (module.type === 'multi_image_layout') {
        module.images.forEach((i) => addItem(i.image, i.credit, i.id))
      }
      if (module.type === 'big_image') {
        module.images.forEach((i) => addItem(i.image, i.credit, i.id))
      }
    })

    setImageItems(items)
  }, [image, blocks])

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Lightbox
        items={imageItems}
        startIndex={startIndex}
        isOpen={isOpen}
        onClose={handleClose}
      />
      {render({ blocks, image })}
    </>
  )
}

ProjectLightbox.propTypes = {
  image: PropTypes.object.isRequired,
  blocks: PropTypes.array.isRequired,
  render: PropTypes.func.isRequired,
}

export { ProjectLightbox }
